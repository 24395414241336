import {axiosTaasInstance as axios} from "../axios.config";
import _ from "lodash";
import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";
import { displayErrorNew } from "../components/common/alerts/alerts";
import {EnvirnomentLabels} from '../components/auth/functions';
import { getUsersForAllocation } from "./myTeamsActions";

const API_URL_GATEWAY = config.tdm_gateway_api.uri;
const TAAS_USER_BASE_URL = config.taas_user_base;

export const retrieveServiceIds = teams => async dispatch => {
  try {

    dispatch({ type: types.FETCH_SERVICE_IDS_PENDING, payload: true });

    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/allocation/service/details`,
      teams,
      { headers: authHeaders() }
    );

    if (response.data.responseStatus === "Success") {
      dispatch({
        type: types.FETCH_SERVICE_IDS_SUCCESS,
        payload: response.data.services
      });
    } else {
      dispatch({ type: types.FETCH_SERVICE_IDS_FAILED });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: types.FETCH_SERVICE_IDS_FAILED });
  }
};

export const fetchAllActiveServiceIds = () => async dispatch => {
  try {
    dispatch({ type: types.FETCH_ALL_SERVICE_IDS_PENDING, payload: true });
    const response = await axios.get(
      `${API_URL_GATEWAY}/tdm/allocation/service/list`,
      { headers: authHeaders() }
    );
    if (response.status === 200) {
      dispatch({
        type: types.FETCH_ALL_SERVICE_IDS_SUCCESS,
        payload: response.data.service
      });
      return response.data.service;
    } else {
      dispatch({ type: types.FETCH_ALL_SERVICE_IDS_FAILED });
      throw new Error(response.data.responseDescription)
    }
  } catch (e) {
    dispatch({ type: types.FETCH_ALL_SERVICE_IDS_FAILED });
    throw e;
  }
};

export const fetchAllTeams = () => async dispatch => {
  try {
    dispatch({ type: types.FETCH_ALL_TEAMS_PENDING, payload: true });
    const response = await axios.get(
      `${API_URL_GATEWAY}/tdm/data/team/active/details`,
      { headers: authHeaders() }
    );
    if (response.status === 200) {
      dispatch({
        type: types.FETCH_ALL_TEAMS_SUCCESS,
        payload: response.data.teams
      });
      return response.data.teams;
    } else {
      dispatch({ type: types.FETCH_ALL_TEAMS_FAILED });
      throw new Error(response.data.responseDescription)
    }
  } catch (e) {
    dispatch({ type: types.FETCH_ALL_TEAMS_FAILED });
    throw e;
  }
};

const fetchTeams = ({ email, isAdmin, myTeamOptions }) => async dispatch => {
  if (myTeamOptions && myTeamOptions.length > 0) {
    return myTeamOptions;
  } else {
    const response = await axios.get(`${TAAS_USER_BASE_URL}/tools/teams?toolName=TDM`, {
      headers: authHeaders({},true)
    });
    return response.data.teamDtos;
  }
};

const loadGenericTeamDetails = async (email, isAdmin, dispatch, myTeamOptions) => {
  const teamDetails = await dispatch(fetchTeams({ isAdmin, email, myTeamOptions })) || [];
  const teams = teamDetails.map(teamDetail => teamDetail.teamId || teamDetail.id);
  return {teams, teamDetails};
}

const loadGenericStoryDetails = async (teamDetails, url, request, dataField, serviceId, description, maxCheckoutQuantity) => {
  const response = await axios.post(url, request, { headers: authHeaders() });
    if (response.data.teamServiceDetailsList) {
      let finalResult= response.data.teamServiceDetailsList.map((_item)=>{
        return {
          teamId: _item.teamServiceDetails.teamOrUserId,
          teamName: _item.teamServiceDetails.teamName,
          serviceId: _item.teamServiceDetails.serviceId,
          description: _item.teamServiceDetails.serviceUniqueName,
          serviceProductCode: _item.teamServiceDetails.productCode ? _item.teamServiceDetails.productCode : '',
          maxCheckoutQuantity : _item.teamServiceDetails.maxCheckoutQuantity,
          quantity: _item.quantity
        }
      });
      return  {payload:finalResult, errorMessage : ''};
    } else {
      const teamUserStoryDetails=response.data.userStoryScenarioDetails;
      if(teamUserStoryDetails){
        if (dataField === 'teamUserStoryScenarioDetails' && _.isEmpty(teamUserStoryDetails)) {
          const errorMessage =  _.get(response, 'data.responseDescription', '');
          return {payload: [], errorMessage};
        }
        const payload = _.flatten(
          teamDetails.map(data => {
            if (teamUserStoryDetails[data.id || data.teamId]) {
              return teamUserStoryDetails[data.id || data.teamId].map(team => {
                return {
                  teamId: data.id || data.teamId,
                  teamName: data.teamName,
                  serviceId: team[serviceId],
                  description: team[description],
                  serviceProductCode: team.serviceProductCode ? team.serviceProductCode : '',
                  maxCheckoutQuantity : team[maxCheckoutQuantity],
                  quantity: team.quantity,
                  scenarioName:team.scenarioName
                };
              });
            } else {
              return {
                teamId: data.id || data.teamId,
                teamName: data.teamName,
                serviceId: "",
                description: "",
                serviceProductCode: "",
                maxCheckoutQuantity : "",
                quantity: "",
                scenarioName:""
              };
            }
          })
        );
        return {payload, errorMessage : ''};
      }
    }
}

const loadGenericServiceList=async (url)=>{
  const response = await axios.get(url, { headers: authHeaders() });
  let filterService;
  if(response.data.service){
     filterService=response.data.service.map((_item)=>{
      return {"value": _item.id, "label": _item.id}
    });
    return  {payload:filterService, errorMessage : ''};
  }
}

const loadTeamDetails = async (teamDetails, url, request, dataField, serviceId, description, maxCheckoutQuantity) => {
  const response = await axios.post(url, request, { headers: authHeaders() });
const teamUserStoryDetails = _.get(response, `data.${dataField}`, []);
if (dataField === 'teamUserStoryScenarioDetails' && _.isEmpty(teamUserStoryDetails)) {
  const errorMessage =  _.get(response, 'data.responseDescription', '');
  return {payload: [], errorMessage};
}
const payload = _.flatten(
       teamUserStoryDetails.map(team => {
        console.log("==== team ====>::"+JSON.stringify(team));
        return {
          teamName: team.teamOrUserId,
          serviceId: team.serviceId,
          description: team.serviceDescription,
          serviceProductCode: team.productCode ? team.productCode : '',
          maxCheckoutQuantity : team.maxCheckoutQuantity,
          quantity: team.quantity
        };
      })
);
return {payload, errorMessage : ''};
}

export const loadTeamFilterDetails = ({
  scopes,
  email,
  isAdmin,
  loggedInUser,
  myTeamOptions
},showAllTeamDetails) => async  (dispatch, getState)  => {
  dispatch({ type: types.LOAD_SOURCE_TEAM, payload: null});
  dispatch({ type: types.LOAD_TEAM_FILTERS_PENDING, payload: true });
  try {
    let url;
    let request;
      let {teams, teamDetails} = await loadGenericTeamDetails(loggedInUser.userName, isAdmin, dispatch, myTeamOptions);
      const selectedEnvValue = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
       //logged in user modified key value as teamDetails
      let logInUser = {loggedInUser}
      logInUser['id'] = loggedInUser.sub
      logInUser["teamName"] = loggedInUser.name
      teamDetails = [logInUser, ...teamDetails]
      
      if(showAllTeamDetails){
        request = {
          userName: logInUser?.loggedInUser?.userName,
          environment: selectedEnvValue,
          team: loggedInUser?.team
        };
        url = `${API_URL_GATEWAY}/tdm/allocation/service/details`;
      }else{
        request = {
          userName: logInUser?.loggedInUser?.userName,
          environment: selectedEnvValue
        };
        url = `${API_URL_GATEWAY}/tdm/standarddatabank/standarddata/service/details`;
      }
      
      let {payload, errorMessage} = await loadGenericStoryDetails(teamDetails, url, request, 'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      
      if(!errorMessage){
        const userService = payload.filter(user=> user.teamId === loggedInUser.sub);
        if(payload.length>0){
          if(payload[0].teamId === loggedInUser.sub){
            payload.shift();
            userService.push(...payload)
            payload = userService;
          }
        }
      } else {
        console.error("errorMessage loadTeamFilterDetails", errorMessage);
      }

      dispatch({ type: types.LOAD_TEAM_FILTERS_SUCCESS, payload });
    } catch (error) {
      const payload = _.get(error, 'response.data.responseDescription', '');
      dispatch({ type: types.LOAD_TEAM_FILTERS_FAILED, payload });
      console.error(error);
    }
};


export const loadCustomTeamFilterDetails = ({
  scopes,
  email,
  isAdmin,
  loggedInUser,
  myTeamOptions
}) => async (dispatch,getState) => {
  dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_PENDING, payload: true });
  try {
      let {teams, teamDetails} = await loadGenericTeamDetails(loggedInUser.userName, isAdmin, dispatch, loggedInUser.myTeamOptions);
       //logged in user modified key value as teamDetails
      let logInUser = {loggedInUser}
      logInUser['teamId'] = loggedInUser.userName
      logInUser["teamName"] = loggedInUser.name
      teamDetails = [logInUser, ...teamDetails]
      teams = [...teams]
      const selectedEnvironment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const request = { teamIds: teams , environment: selectedEnvironment};
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`
      const {payload, errorMessage }= await loadGenericStoryDetails(teamDetails, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');
      let userIds = [];
      userIds.push(loggedInUser.userName);
      try{
        const userPayload = await loadGenericStoryDetails(teamDetails, url, { userIds , 'environment': selectedEnvironment} ,'userStoryScenarioDetails', 'userStory', 'scenarioName');
        if(!userPayload.errorMessage){
          const userService = userPayload.payload.filter(user=> user.teamId === loggedInUser.userName);
          if(payload.length>0){
            if(payload[0].teamId === loggedInUser.userName){
              payload[0] = userService[0];
            }
          }
        }else{
          console.error("userPayload loadCustomTeamFilterDetails", userPayload);
        }
      }catch (error){
        console.error("error loadCustomTeamFilterDetails", error);
      }
      if (!_.isEmpty(errorMessage) && _.isEmpty(payload)) {
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload: errorMessage });
        return;
      }

      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS, payload });
    } catch(error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload });
        console.error(error);
    }
};

export const selectCustomTeamFilter = (selectedCustomFilter) => async dispatch => {
  try {
    dispatch({ type: types.SELECT_CUSTOM_TEAM_FILTER, payload: selectedCustomFilter });
  } catch (e) {
    console.error(e);
  }
};

export const selectFilter = (selectedFilters) => async dispatch => {
  try {
    dispatch({ type: types.SELECT_TEAM_FILTER,  payload: selectedFilters });
  } catch (e) {
    console.error(e);
  }
};

export const checkoutTeam = request => async dispatch => {
  try {
    dispatch({ type: types.TEAM_CHECKOUT_PENDING, payload: true });
    dispatch({ type: types.FORCE_FEEDBACK, payload: false });
    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/standarddatabank/checkout/assets`,
      request,
      { headers: authHeaders() }
    );

    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.TEAM_CHECKOUT_SUCCESS });
      dispatch({ type: types.FORCE_FEEDBACK, payload: true });
      return { checkoutId: response.data.checkoutId, }
    } else {
      dispatch({ type: types.TEAM_CHECKOUT_FAILED });
      dispatch({ type: types.FORCE_FEEDBACK, payload: false });
      const errorMsg = response.data.responseDescription
      displayErrorNew({
        title: "Checkout Failed",
        subTitle: "An error occured while Checking out!",
        errorMsg
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: types.TEAM_CHECKOUT_FAILED });
    dispatch({ type: types.FORCE_FEEDBACK, payload: false });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";

    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while Checking Out.",
      errorMsg

    });
  }
};

export const checkoutServiceFilters = request => async (dispatch,getState) => {
  try {
    const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
    dispatch({ type: types.TEAM_SERVICE_FILTERS_PENDING, payload: true });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/filter?serviceId=${request}&env=${environment}`,{ headers: authHeaders() });

    if (response.status === 200) {
      dispatch({ type: types.TEAM_SERVICE_FILTERS_SUCCESS, payload: response.data.resourceFilterData });
      return response.data.resourceFilterData;
    } else {
      const errorMsg = response.data.responseDescription
      dispatch({ type: types.TEAM_SERVICE_FILTERS_FAILED, payload: errorMsg });
    }
  } catch (e) {
    const payload =  _.get(e, 'response.data.responseDescription', '');
    dispatch({ type: types.TEAM_SERVICE_FILTERS_FAILED, payload });
    console.error(e);
  }
}
export const allocateServiceFilters = request => async (dispatch,getState) => {
  try {
    const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
    dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_PENDING, payload: true });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/filter?serviceId=${request.value}&env=${environment}`,{ headers: authHeaders() });

    if (response.status === 200 && !(_.isEmpty(response.data.resourceFilterData))) {
      dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_SUCCESS, payload: response.data.resourceFilterData });
      return response.data.resourceFilterData;
    } else {

      const errorMsg = response.data.message
      dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_FAILED, payload: errorMsg });
    }
  } catch (e) {
    const payload =  _.get(e, 'response.data.message', '');
    dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_FAILED, payload });
    console.error(e);
  }
}
export const checkoutServiceFilterValue = request => async dispatch => {
  try {
    dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_PENDING, payload: true });
    const response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/filter/value`,request,{ headers: authHeaders() });

    if (response.status === 200 && !(_.isEmpty(response.data.filterValues))) {
      dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_SUCCESS, payload: response.data.filterValues});
    } else {
      const errorMsg = response.data.message ? response.data.message : response.data.responseDescription;
      dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_FAILED, payload: errorMsg });
    }
  } catch (e) {
    const payload =  _.get(e, 'response.data.message', '');
    dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_FAILED, payload });
    console.error(e);
  }
}

export const allocateToTeam = request => async dispatch => {
  try {
    dispatch({ type: types.TEAM_ALLOCATION_PENDING, payload: true });
    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/allocation/team/allocation`,
      request,
      { headers: authHeaders() }
    );

    const status = response.data.responseStatus;
    const responseDescription = response.data.responseDescription;
    if (status == "Success") {
      dispatch({ type: types.TEAM_ALLOCATION_SUCCESS });
      return { responseDescription };
    } else {
      const errorMsg = error.message[0];
      dispatch({ type: types.TEAM_ALLOCATION_FAILED });
      displayErrorNew({
        title: "Allocation Error",
        subTitle: "An error occured while allocating to team ",
        errorMsg
      });
    }

  } catch (e) {
    console.log("e----> ", e.response)
    console.log("e----> ", e.response.data)
    console.error(e);
    dispatch({ type: types.TEAM_ALLOCATION_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.error.message[0];
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Allocation Error",
      subTitle: "An error occured while allocating to team ",
      errorMsg
    });
  }
};

export const loadUserFilterDetails = ({loggedInUser, currentTeamId, userList}) => async  (dispatch,getState)  => {

  try {
      dispatch({ type: types.LOAD_TEAM_FILTERS_PENDING, payload: true });
      const users = await dispatch(getUsers(userList));
      const userIds = _.map(users, 'id');
      const url = `${API_URL_GATEWAY}/tdm/allocation/service/details`;
      const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      let logInUser = {loggedInUser};
      const userName = logInUser.loggedInUser.userName;
      const team = currentTeamId;
      const {payload, errorMessage} = await loadGenericStoryDetails(users, url, { userName, team, environment} ,'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      dispatch({ type: types.LOAD_TEAM_FILTERS_SUCCESS, payload });
    } catch (error) {
      const payload = _.get(error, 'response.data.responseDescription', '');
      dispatch({ type: types.LOAD_TEAM_FILTERS_FAILED, payload });
      console.error(error);
    }
};
//End of loadUserFilterDetails

/*
 Author : Sourav
 loadCustomUserFilterDetails:
*/
export const loadCustomUserFilterDetails = (userList) => async (dispatch,getState) => {

  try {
      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_PENDING, payload: true });
      const users = await dispatch(getUsers(userList));
      const userIds = _.map(users, 'id');
      const selectedEnvironment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`
      const request = { userIds: userIds , environment: selectedEnvironment};
      const {payload, errorMessage }= await loadGenericStoryDetails(users, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');

      if (!_.isEmpty(errorMessage) && _.isEmpty(payload)) {
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload: errorMessage });
        return;
      }

      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS, payload });
    } catch(error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload });
        console.error(error);
    }
};
// End of loadCustomUserFilterDetails

export const fetchClassTechOptions = () => async dispatch => {
  try {
    const url = `${API_URL_GATEWAY}/tdm/data/fetch/nbn/class/technology/details`
    const response = await axios.get(url, { headers: authHeaders()});

    const payload = _.sortBy(response.data.classDetails, 'classId');
    dispatch({ type: types.TEAM_CLASS_TECH_SUCCESS, payload });
    return payload;
    } catch (e) {
    console.error(e);
    dispatch({ type: types.TEAM_CLASS_TECH_FAILED });
  }
};

export const getTeamsForAllocation = () => async (dispatch,getState)  => {
  let {teams}=getState();
  let allTeams=teams.teams.allTeams;
  try {
    let teams = [];
    let error = null;
    if (allTeams && allTeams.length > 0) {
      teams = allTeams;
    } else {
      const response = await axios.get(
        `${API_URL_GATEWAY}/tdm/data/team/active/details`,
        { headers: authHeaders() }
      );
  
      if (response.status === 200) {
        teams = response.data.teams;
      } else {
        error = response.data.responseDescription;
        console.error(response.data.responseDescription);
      }
    }
    return {teams, error};
  } catch (e) {
    console.error(e);
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";
    console.error("getTeamsForAllocation myteam",errorMsg);
  }
};

export const getTeamSource = (selectedEnvValue, selectedToggle, loggedInUser, allTeams) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_PENDING});
    let url = null;
    let id = null;
    let teamService = {};
    let serviceList;
    const environment = selectedEnvValue || EnvirnomentLabels.INT2;
    const {teams, error} = await dispatch(getTeamsForAllocation(allTeams));
    if(teams.length === 0){
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
      return;
    }
    const teamIds = _.map(teams, 'teamId');

    if(selectedToggle === 'Standard'){
      const serviceListUrl=`${API_URL_GATEWAY}/tdm/allocation/service/list`;
      serviceList = await loadGenericServiceList(serviceListUrl);
      id = teams.find((team)=>team.teamName === 'TEAM_CATALYST');
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_SUCCESS, payload: {service:[], serviceIdOptions:serviceList.payload, defaultSourceId:id.id}});
      return;
    }
    /* Scenario name is going to empty string so we comment this userstories API */
    else if(selectedToggle === 'Custom'){

      const request = {teamIds, environment};
      id = teams.find((team)=>team.teamName === 'TDM OPS');
        dispatch({ type: types.LOAD_ALLOCATE_SOURCE_SUCCESS, payload: {service:request, serviceIdOptions:[], defaultSourceId:id.id}});
    }

    else{
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: teamService.errorMessage});
    }
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload});
    console.error(error);
  }
}

export const getUserSource = (selectedEnvValue, selectedToggle, loggedInUser, userList) => async (dispatch) => {
  let userService = {};
  try {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_PENDING});
    let url = null;

    const environment = selectedEnvValue || EnvirnomentLabels.INT2;
    const {users, error} = await dispatch(getUsersForAllocation(userList));
    if(users.length === 0){
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
      return;
    }
    const userIds = _.map(users, 'userId');
    if(selectedToggle === 'Standard'){
      let logInUser = {loggedInUser};
      const userName = logInUser.loggedInUser.userName;
      const team = currentTeamId;
      const request = {userName, team, environment};
      url = `${API_URL_GATEWAY}/tdm/allocation/service/details`;
      userService = await loadGenericStoryDetails(users, url, request,'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');

    }else if(selectedToggle === 'Custom'){
      const request = {userIds, environment};
      url = `${API_URL_GATEWAY}/tdm/standarddatabank/team/fetch/userstories `;
      userService = await loadGenericStoryDetails(users, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');
    }
    if(userService.payload.length>0 ){
      let serviceIds = _.uniq(_.map(userService.payload, 'serviceId'))
      serviceIds.map((sId)=>typeof(sId) === 'number'? serviceIds.sort((id1, id2)=>id1 - id2):serviceIds.sort());
      const serviceIdOptions = serviceIds.map(serviceId=>({value: serviceId, label: serviceId}));

      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_SUCCESS, payload: {service:userService.payload, serviceIdOptions}});
    }else{
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: userService.errorMessage });
    }
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload });
    console.error(error);
  }
}

export const fetchExchangeIDs = (env) => async (dispatch) => {
  try{
    dispatch({ type: types.LOAD_ALLOCATE_EXCHANGE_ID_PENDING, payload: [] });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/standarddatabank/standarddata/exchanges/${env}`, { headers: authHeaders() });
   if(response.data.exchangeIdTeamData){
    return dispatch({ type: types.LOAD_ALLOCATE_EXCHANGE_ID_SUCCESS, payload: response.data.exchangeIdTeamData});
   } dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: [] });
  }
  catch(e)
  {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: [] });
  }

}

export const getTeamsList=()=> async () => {
  try {
      const response = await axios.get(
        `${API_URL_GATEWAY}/tdm/allocation/active/team/list`,
        { headers: authHeaders() }
      );
      let teams = response.data.teams;
    return teams;
  } catch (err) {
    console.error("getTeamsForAllocation myteam",err);
  }
}

export const getTeamUserTarget = (targetUserToggle, selectedSource, selectedUserStory) => async (dispatch,getState) => {
  try {
    let {teams}=getState();
    let allTeams=teams.teams.allTeams;
    dispatch({ type: types.LOAD_ALLOCATE_TARGET_PENDING});
    let targetOptions = [];
    if(targetUserToggle){
      const {users, error} = await dispatch(getUsersForAllocation(userList));
      const prepareOptions = selectedSource?.value && selectedSource.label ? users.filter((sd)=> sd.userId !== selectedSource.value && sd.teamName !== selectedSource.label):[];
      targetOptions = prepareOptions.map(option=>({ value: option.userId, label: option.teamName }));
      if(users.length === 0){
        dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
        return;
      }
    }else{
      const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const response = await axios.get(`${API_URL_GATEWAY}/tdm/standarddatabank/custom/teams/assets?env=${environment}&userStory=${selectedUserStory}`, { headers: authHeaders() });
      const {teamuserstories}= response.data;
      let sourceTeamList=teamuserstories.map((_item)=>{
        return {
          label:_item.teamName,
          quantity:_item.quantity,
          value:_item.teamId
        }
      });

       let compactSourceTeamList=_.compact(sourceTeamList);
       dispatch({ type: types.LOAD_SOURCE_TEAM, payload: compactSourceTeamList});
      if (allTeams && allTeams.length > 0) {
        teams = allTeams;
        targetOptions = teams.map(option=>({ value: option.teamId, label: option.teamName }));
      }else{
        teams= await dispatch(getTeamsList(allTeams));
        targetOptions = teams.map(option=>({ value: option.teamId, label: option.teamName }));
      }
      if(teams.length === 0){
        dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
        return;
      }
    }
    dispatch({ type: types.LOAD_ALLOCATE_TARGET_SUCCESS, payload: {targetOptions}});
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_TARGET_FAILED, payload});
    console.error(error);
  }
}

export const getSourceTeam=(selectedServiceId,targetUserToggle,allTeams,selectedToggleButton)=>async(dispatch,getState) =>{
  let selectedSource;
  if(selectedToggleButton==="Standard"){
  const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
  const response = await axios.get(`${API_URL_GATEWAY}/tdm/allocation/assets/team/serviceId?env=${environment}&serviceId=${selectedServiceId}`, { headers: authHeaders() });
  const {teamServiceDetailsList}= response.data;
  const allocateSourceData= getState().teams.allocateSourceData;
  const sourceTeamOptions=teamServiceDetailsList.map((_item)=>{
    const {teamServiceDetails} =_item;
    return {
      label:teamServiceDetails.teamName,
      quantity:_item.quantity,
      value:teamServiceDetails.teamOrUserId
    }
  });
  const sourceTeam = sourceTeamOptions.find(option => option.value === allocateSourceData.source.defaultSourceId);
  selectedSource = sourceTeam && sourceTeam.value && sourceTeam.label;
  let teams=[];
  let targetOptions=[];
  if (allTeams && allTeams.length > 0) {
    teams = allTeams;
    targetOptions = teams.map(option=>({ value: option.teamId, label: option.teamName }));
  }else{
    teams= await dispatch(getTeamsList(allTeams));
    targetOptions = teams.map(option=>({ value: option.teamId, label: option.teamName }));
  }
  if(teams.length === 0){
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
    return;
  }
  dispatch({ type: types.LOAD_SOURCE_TEAM, payload: sourceTeamOptions});
  dispatch({ type: types.LOAD_ALLOCATE_TARGET_SUCCESS, payload: {targetOptions}});
}else{
  await dispatch(getTeamUserTarget(targetUserToggle, selectedSource,selectedServiceId));
}
}

export const getCustomSourceTeam=(inputUserStory,targetUserToggle,allTeams,selectedToggleButton)=>async(dispatch,getState) =>{
  await dispatch(getTeamUserTarget(targetUserToggle, selectedSource,inputUserStory));
}